import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ScrollableFeed from 'react-scrollable-feed';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import { Form } from '@rocketseat/unform';
import { format, formatDistanceToNow, isToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  titulo: {
    height: '80px',
    padding: theme.spacing(2, 3),
  },
  tituloLabel: {
    color: '#4D5884',
    fontSize: '16px',
  },
  tituloTrabalho: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  form: {
    background: '#50A6FF',
    padding: '8px 24px',
    maxHeight: '73px',
    borderRadius: '0px 0px 8px 8px',
  },
  icon: {
    color: '#50A6FF',
  },
  chat: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  messages: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    background: '#F7F8FA',
    paddingLeft: theme.spacing(1),
    borderRadius: '8px 8px 0 0',
  },
  autor: {
    color: '#8F00FF',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  mensagemContainer: {
    height: 'fit-content',
    margin: theme.spacing(1, 0),
    paddingRight: theme.spacing(1),
  },
  mensagemProfessor: {
    background: '#E1E8F2',
    padding: theme.spacing(1),
    borderRadius: '16px 16px 16px 0px',
  },
  mensagemAluno: {
    background: '#97A4BF',
    padding: theme.spacing(1),
    borderRadius: '16px 16px 0 16px',
  },
  textProfessor: {
    fontSize: '13px',
    color: '#465471',
  },
  dateProfessor: {
    fontSize: '11px',
    textAlign: 'end',
    color: '#97A4BF',
  },
  textAluno: {
    fontSize: '13px',
    color: '#FFFFFF',
  },
  dateAluno: {
    fontSize: '11px',
    textAlign: 'end',
    color: 'rgba(255, 255, 255, 0.53)',
  },
}));

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      background: '#fff',
    },
    '& .MuiFocused': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
})(TextField);

export default function ComentariosAtividade({ resposta }) {
  const classes = useStyles();
  const [mensagens, setMensagens] = useState(resposta.anotacoes || []);
  const [mensagemDigitada, setMensagemDigitada] = useState(null);
  const ref = useRef(null);

  async function handleSubmit() {
    if (!!mensagemDigitada) {
      const newMensagens = [...mensagens];
      newMensagens.push({
        id: mensagens.length + 1,
        text: mensagemDigitada,
        tipo: 'professor',
        data: new Date(),
      });

      setMensagens(newMensagens);
      setMensagemDigitada(null);
      ref.current.value = '';

      await api.post(`/anotacao/${resposta.realizaratividade_id}`, {
        id: mensagens.length + 1,
        text: mensagemDigitada,
        data: new Date(),
      });
    }
  }

  useEffect(() => {
    async function getAnotacoes() {
      const response = await api.get(
        `/anotacoes/${resposta.realizaratividade_id}`
      );
      setMensagens(response.data);
    }

    getAnotacoes();
  }, [resposta.realizaratividade_id]);

  function getDataFormatada(data) {
    data = new Date(data);
    if (isToday(data)) {
      return formatDistanceToNow(data, {
        includeSeconds: true,
        addSuffix: 'true',
        locale: ptBR,
      });
    }

    return format(data, "dd/MM/yyyy à's' HH:mm", { locale: ptBR });
  }

  return (
    <div className={classes.chat}>
      <div className={classes.titulo}>
        <Typography component="p" className={classes.tituloLabel}>
          Comentário para
        </Typography>
        <Typography component="p" className={classes.tituloTrabalho}>
          {resposta.titulo}
        </Typography>
      </div>
      <div className={classes.messages}>
        <ScrollableFeed>
          {mensagens &&
            mensagens.map(mensagem => {
              const isMensagemAluno = mensagem.tipo === 'professor';
              return (
                <Grid
                  container
                  className={classes.mensagemContainer}
                  justifyContent={`${
                    isMensagemAluno ? 'flex-end' : 'flex-start'
                  }`}
                  key={mensagem.id}
                >
                  <Grid
                    item
                    xs={7}
                    className={
                      classes[
                        `mensagem${isMensagemAluno ? 'Aluno' : 'Professor'}`
                      ]
                    }
                  >
                    {!isMensagemAluno && (
                      <Typography component="p" className={classes.autor}>
                        {mensagem.name}
                      </Typography>
                    )}

                    <Typography
                      component="p"
                      className={
                        classes[
                          `text${isMensagemAluno ? 'Aluno' : 'Professor'}`
                        ]
                      }
                    >
                      {mensagem.text}
                    </Typography>
                    <Typography
                      component="p"
                      className={
                        classes[
                          `date${isMensagemAluno ? 'Aluno' : 'Professor'}`
                        ]
                      }
                    >
                      {getDataFormatada(mensagem.data)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </ScrollableFeed>
      </div>
      <div className={classes.form}>
        <Form onSubmit={handleSubmit}>
          <StyledTextField
            name="mensagem"
            placeholder="Sua mensagem..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <SendOutlined className={classes.icon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            className={classes.field}
            fullWidth
            color="secondary"
            autoComplete="off"
            onChange={e => setMensagemDigitada(e.target.value)}
            inputRef={ref}
          />
        </Form>
      </div>
    </div>
  );
}

ComentariosAtividade.propTypes = {
  resposta: PropTypes.shape().isRequired,
};
