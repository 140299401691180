import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Grid,
  Typography,
  Badge,
} from '@material-ui/core';
import {
  ArrowForward,
  CommentOutlined,
  AttachFileOutlined,
} from '@material-ui/icons';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';

import api from '~/services/api';

import AtividadesAlunoModal from './AtividadeAlunoModal';
import RespostasQuestionarioModal from './RespostasQuestionarioModal';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '559px',
    height: '597px',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
  },
  header: {
    padding: theme.spacing(2, 3),
    background: '#50A6FF',
    color: '#fff',
  },
  notifications: {
    color: '#4D5884',
  },
}));

export default function AtividadeTurmaAlunosModal({
  open,
  handleClose,
  atividadesRealizadas,
  atividadeLancada,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openQuestionario, setOpenQuestionario] = useState(false);
  const [atividades, setAtividades] = useState(null);
  const [respostaAluno, setRespostaAluno] = useState(null);
  const [atualizar, setAtualizar] = useState(false);

  useEffect(() => {
    async function getAtividadeRealizadas() {
      const response = await api.get('/atividades-realizadas/', {
        params: { atividadesRealizadas },
      });
      setAtividades(response.data);
    }

    if (atividadesRealizadas.length && (!atividades || atualizar)) {
      setAtualizar(false);
      getAtividadeRealizadas();
    }
  }, [atividadesRealizadas, atividades, atualizar]);

  function handleCloseModal() {
    setOpenModal(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1} className={classes.header}>
              <Grid item xs={5}>
                <Typography variant="caption" component="p">
                  MATÉRIA
                </Typography>
                <Typography variant="h6" component="p">
                  {Array.isArray(atividadeLancada)
                    ? atividadeLancada[0].materia
                    : atividadeLancada.materia}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" component="p">
                  TURMA
                </Typography>
                <Typography variant="h6" component="p">
                  {Array.isArray(atividadeLancada)
                    ? atividadeLancada[0].turma
                    : atividadeLancada.turma}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption" component="p">
                  AULA
                </Typography>
                <Typography variant="h6" component="p">
                  {Array.isArray(atividadeLancada)
                    ? atividadeLancada[0].aula
                    : atividadeLancada.aula}
                </Typography>
              </Grid>
            </Grid>
            <DataGrid
              dataSource={atividades}
              showBorders
              noDataText="Não houve resposta para esta atividade"
            >
              <Column
                caption="ID"
                dataField="realizaratividade_id"
                visible={false}
              />
              <Column caption="STATUS" dataField="status" />
              <Column caption="ALUNO" dataField="aluno" />
              <Column caption="N° CONTRATO" dataField="numerocontrato" />
              <Column type="buttons">
                <Button
                  render={e => {
                    if (e.data.hasnewcomment === 'true') {
                      return (
                        <Badge color="error" variant="dot">
                          <CommentOutlined className={classes.notifications} />
                        </Badge>
                      );
                    }
                  }}
                />
                <Button
                  render={e => {
                    if (e.data.hasnewfile === 'true') {
                      return (
                        <Badge color="error" variant="dot">
                          <AttachFileOutlined
                            className={classes.notifications}
                          />
                        </Badge>
                      );
                    }
                  }}
                />
              </Column>
              <Column type="buttons">
                <Button
                  render={e => {
                    const correcaoHabilitada =
                      (e.data.questionario && e.data.analise) ||
                      !e.data.questionario;
                    return (
                      <>
                        {correcaoHabilitada && (
                          <IconButton
                            onClick={() => {
                              setRespostaAluno(e.data);
                              if (e.data.questionario) {
                                setOpenQuestionario(true);
                              } else {
                                setOpenModal(true);
                              }
                            }}
                          >
                            <ArrowForward color="primary" />
                          </IconButton>
                        )}
                      </>
                    );
                  }}
                />
              </Column>
            </DataGrid>
            {openModal && (
              <AtividadesAlunoModal
                open={openModal}
                handleClose={handleCloseModal}
                resposta={respostaAluno}
                atividadeLancada={atividadeLancada}
                atualizarTabela={setAtualizar}
              />
            )}
            {respostaAluno && openQuestionario && (
              <RespostasQuestionarioModal
                open={openQuestionario}
                setOpen={setOpenQuestionario}
                atividadeRealizada={respostaAluno.realizaratividade_id}
                respostaAluno={respostaAluno}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

AtividadeTurmaAlunosModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  atividadesRealizadas: PropTypes.shape().isRequired,
  atividadeLancada: PropTypes.shape().isRequired,
};
