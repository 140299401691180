import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Button,
  Typography,
  IconButton,
  Slider,
} from '@material-ui/core';
import { Chat, EditOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { darken } from 'polished';
import { useConfirm } from 'material-ui-confirm';

import api from '~/services/api';
import ComentariosAtividade from '../AtividadeAlunoModal/ComentariosAtividade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: '854px',
    maxHeight: '572px',
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
  },
  header: {
    maxHeight: '72px',
    height: '100%',
    background: '#4D5884',
    borderRadius: '8px 8px 0 0',
  },
  containerHeader: {
    height: '100%',
    padding: '10px',
  },
  footer: {
    background: 'rgba(234, 236, 245, 0.4)',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  body: {
    height: '100%',
    maxHeight: '405px',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  containerBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tableHeader: {
    marginBottom: '10px',
  },
  resposta: {
    background: 'rgba(255, 255, 255, 0.4)',
    borderRadius: '12px',
    marginTop: '15px',
  },
  respostaCorreta: {
    border: '1px solid #47D19D',
  },
  respostaIncorreta: {
    border: '1px solid #FF9239',
  },
  questaoSemResposta: {
    border: '1px solid #BDC0D5',
  },
  gridContentCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridLabel: {
    color: '#4D5884',
    fontWeight: 'bold',
  },
  gridValue: {
    color: '#50A6FF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  number: {
    borderRadius: '12px 0 0 12px',
    fontSize: '20px',
    color: '#fff',
  },
  tagFlag: {
    padding: '5px 8px',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '10px',
  },
  correta: {
    background: '#47D19D',
  },
  incorreta: {
    background: '#FF9239',
  },
  semResposta: {
    background: '#BDC0D5',
  },
  tagResposta: {
    background: '#50A6FF',
    padding: '5px 8px',
    borderRadius: '4px',
    marginLeft: '8px',
    color: '#fff',
    fontSize: '10px',
  },
  button: {
    minWidth: '183px',
    background: '#50A6FF',
    color: '#fff',
    marginLeft: '25px',
    borderRadius: '200px',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  buttonExit: {
    minWidth: '183px',
    borderRadius: '200px',
  },
  label: {
    color: '#50A6FF',
  },
  value: {
    color: '#fff',
  },
  valueNome: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nome: {
    color: '#fff',
  },
  textoResposta: {
    maxHeight: '200px',
    overflow: 'auto',
    color: '#4D5884',
  },
  labelResposta: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: '10px 0 5px 0',
  },
}));

export default function RespostasQuestionarioModal({
  open,
  setOpen,
  atividadeRealizada,
  respostaAluno,
}) {
  const SELECAO_UNICA = 1;
  const TEXTO_CURTO = 2;
  const TEXTO_LONGO = 3;
  const VERDADEIRO_FALSO = 4;
  const MULTIPLA_ESCOLHA = 5;

  const classes = useStyles();
  const confirm = useConfirm();

  const [respostas, setRespostas] = useState([]);
  const [questionario, setQuestionario] = useState(null);
  const [notaFinal, setNotaFinal] = useState(null);
  const [aluno, setAluno] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const getRespostas = async () => {
    await api
      .get(`/atividade-realizada/${atividadeRealizada}/questionario/respostas`)
      .then(response => {
        if (response.data.respostas) {
          setRespostas(response.data.respostas);
          setQuestionario(response.data.questionario);
          setAluno(response.data.aluno);

          const notaFinalRespostas = response.data.respostas.reduce(
            (accumulator, currentValue) =>
              accumulator + (Number(currentValue.nota) || 0),
            0
          );
          setNotaFinal(notaFinalRespostas);
        } else {
          setOpen(false);
          toast.info('O aluno ainda não respondeu o questionário.');
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const finalizarCorrecao = async () => {
    await api
      .put(
        `/atividade-realizada/${atividadeRealizada}/questionario/respostas`,
        respostas
      )
      .then(() => {
        toast.success('Correção finalizada com sucesso!');
      });
    setOpen(false);
  };

  useEffect(() => {
    if (!respostas.length) {
      getRespostas();
    }
  }, []); // eslint-disable-line

  const saveNota = (questao, nota) => {
    const newRespostas = [...respostas];
    newRespostas.map(respostaMap => {
      if (respostaMap.questao_id === questao.id) {
        respostaMap.nota = nota;
      }
    });

    setRespostas(newRespostas);
  };

  const putNota = (questao, resposta) => {
    let notaResposta = resposta.nota || 0;

    confirm({
      title: 'Escolha a nota',
      content: (
        <>
          <Typography id="discrete-slider" gutterBottom>
            Questão: {questao.enunciado}
          </Typography>
          <Typography variant="caption">Peso: {questao.peso}</Typography>
          <Slider
            defaultValue={resposta.nota || 0}
            getAriaValueText={0}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={questao.peso}
            onChangeCommitted={e => {
              notaResposta = Number(e.target.innerText) || 0;
            }}
          />
        </>
      ),
      confirmationText: 'Continuar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        className: classes.buttonDialog,
      },
      cancellationButtonProps: {
        variant: 'contained',
        color: 'secondary',
        className: classes.buttonDialog,
      },
    })
      .then(() => {
        saveNota(questao, notaResposta);
      })
      .catch(() => {});
  };

  const checkCorrect = (resposta, questao) => {
    if (resposta.acertou !== undefined) {
      return resposta.acertou;
    }

    return questao.opcoes.some(
      opcao => opcao.correta && resposta.resposta.includes(opcao.id)
    );
  };

  const handleOpenChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const showResposta = questao => {
    const respostaQuestao = respostas.filter(
      resposta => Number(questao.id) === Number(resposta.questao_id)
    );

    const questaoRespondida = Boolean(respostaQuestao.length);
    let opcoesCorretas = '';
    let descricaoCorretas = '';
    let textoResposta = '';

    if (
      [SELECAO_UNICA, VERDADEIRO_FALSO, MULTIPLA_ESCOLHA].includes(questao.tipo)
    ) {
      respostaQuestao[0].acertou = checkCorrect(respostaQuestao[0], questao);
      if (respostaQuestao[0].acertou) {
        respostaQuestao[0].nota = questao.peso;
      }
      // eslint-disable-next-line
      questao.opcoes.map(opcao => {
        if (opcao.correta) {
          if (!opcoesCorretas) {
            opcoesCorretas = opcoesCorretas.concat(`${opcao.id}`);
            descricaoCorretas = descricaoCorretas.concat(`${opcao.descricao}`);
          } else {
            opcoesCorretas = opcoesCorretas.concat(`, ${opcao.id}`);
            descricaoCorretas = descricaoCorretas.concat(
              `, ${opcao.descricao}`
            );
          }
        }
      });
    } else {
      textoResposta = questaoRespondida && respostaQuestao[0].resposta;
    }

    let classeTag = classes.semResposta;
    let classeBorder = classes.questaoSemResposta;
    let textTag = 'NÃO RESPONDEU';

    if (questaoRespondida && respostaQuestao[0].acertou === undefined) {
      textTag = 'AGUARDANDO CORREÇÃO';
    }

    if (questaoRespondida && respostaQuestao[0].acertou) {
      classeTag = classes.correta;
      classeBorder = classes.respostaCorreta;
      textTag = 'RESPONDEU CERTO';
    }

    if (questaoRespondida && respostaQuestao[0].acertou === false) {
      classeTag = classes.incorreta;
      classeBorder = classes.respostaIncorreta;
      textTag = 'RESPONDEU ERRADA';
    }

    return (
      <Grid
        container
        className={clsx(classes.resposta, classeBorder)}
        spacing={1}
      >
        <Grid
          item
          xs={1}
          className={clsx(classes.gridContentCenter, classes.number, classeTag)}
        >
          {questao.id}
        </Grid>
        <Grid item xs={7} className={classes.gridValue}>
          <Typography variant="body2" component="p">
            {questao.enunciado}
          </Typography>
          <div>
            <Typography
              variant="caption"
              component="label"
              className={clsx(classes.tagFlag, classeTag)}
            >
              {textTag}
            </Typography>
            {questaoRespondida && respostaQuestao[0].acertou !== undefined && (
              <Typography
                variant="caption"
                component="label"
                className={classes.tagResposta}
              >
                CERTA(S): {opcoesCorretas}
              </Typography>
            )}
          </div>
          {[SELECAO_UNICA, VERDADEIRO_FALSO, MULTIPLA_ESCOLHA].includes(
            questao.tipo
          ) && (
            <div>
              <Typography
                variant="caption"
                component="p"
                className={classes.labelResposta}
              >
                Resposta
              </Typography>
              <Typography
                variant="caption"
                component="p"
                className={classes.textoResposta}
              >
                {(Array.isArray(respostaQuestao[0].resposta) &&
                  respostaQuestao[0].resposta.join(', ')) ||
                  respostaQuestao[0].resposta}
              </Typography>
            </div>
          )}
          {Boolean(textoResposta) &&
            [TEXTO_CURTO, TEXTO_LONGO].includes(questao.tipo) && (
              <>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.labelResposta}
                  >
                    Resposta
                  </Typography>
                </div>
                <div className={classes.textoResposta}>
                  <Typography variant="caption" component="p" color="inherit">
                    {textoResposta}
                  </Typography>
                </div>
              </>
            )}
        </Grid>
        <Grid
          item
          xs={2}
          className={clsx(classes.gridContentCenter, classes.gridValue)}
        >
          {questao.peso &&
          !isNaN(Number(questao.peso)) && // eslint-disable-line
            parseFloat(questao.peso).toFixed(2)}
        </Grid>
        <Grid
          item
          xs={2}
          className={clsx(classes.gridContentCenter, classes.gridLabel)}
        >
          {(questaoRespondida &&
            respostaQuestao[0].nota &&
            parseFloat(respostaQuestao[0].nota).toFixed(2)) ||
            ([TEXTO_CURTO, TEXTO_LONGO].includes(questao.tipo) && '-') ||
            0}
          {[TEXTO_CURTO, TEXTO_LONGO].includes(questao.tipo) && (
            <IconButton onClick={() => putNota(questao, respostaQuestao[0])}>
              <EditOutlined />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open && Boolean(respostas.length)}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid container component="div" className={classes.paper}>
            <Grid item xs={12} lg={isChatOpen ? 7 : 12}>
              <Grid
                container
                className={classes.container}
                justifyContent="space-between"
              >
                <Grid xs={12} className={classes.header}>
                  <Grid container className={classes.containerHeader}>
                    <Grid xs={6} lg={4} className={classes.valueNome}>
                      <Typography className={classes.nome} variant="body1">
                        {aluno}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={3}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        Questionário
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.value}
                      >
                        {questionario && questionario.titulo}
                      </Typography>
                    </Grid>
                    <Grid xs={5} lg={2}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        Peso total
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.value}
                      >
                        {questionario && questionario.peso_total.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid xs={5} lg={2}>
                      <Typography
                        variant="caption"
                        component="p"
                        className={classes.label}
                      >
                        Nota final
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.value}
                      >
                        {notaFinal ? notaFinal.toFixed(2) : 0}
                      </Typography>
                    </Grid>
                    <Grid xs={2} lg={1}>
                      <IconButton
                        onClick={handleOpenChat}
                        className={classes.chatButton}
                        color="primary"
                      >
                        <Chat />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.body}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <Grid container className={classes.tableHeader}>
                        <Grid
                          item
                          xs={1}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          #
                        </Grid>
                        <Grid item xs={7} className={classes.gridLabel}>
                          Enunciado
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          Peso
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={clsx(
                            classes.gridContentCenter,
                            classes.gridLabel
                          )}
                        >
                          Nota
                        </Grid>
                      </Grid>
                      {questionario &&
                        Boolean(questionario.questoes.length) &&
                        questionario.questoes.map(questao =>
                          showResposta(questao)
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.footer}>
                  <Button
                    variant="contained"
                    className={classes.buttonExit}
                    color="secondary"
                    onClick={() => setOpen(false)}
                    size="small"
                  >
                    SAIR
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => finalizarCorrecao()}
                    size="small"
                  >
                    FINALIZAR
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {isChatOpen && (
              <>
                <Grid item xs={12} lg={1} />
                <Grid item xs={12} lg={4} className={classes.container}>
                  <ComentariosAtividade resposta={respostaAluno} />
                </Grid>
              </>
            )}
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
}

RespostasQuestionarioModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  atividadeRealizada: PropTypes.number.isRequired,
  respostaAluno: PropTypes.shape().isRequired,
};
